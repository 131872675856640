import { createAsyncThunk, unwrapResult } from '@reduxjs/toolkit';
import { fetchJSON, postJSON, putJSON } from 'api/fetch';
import queryString from 'query-string';

import {
  hideModal,
  showModal,
  showNewLocationsSuccessModal,
  showNewPayrollLocationSuccessModal,
} from 'actions/modals';
import { addNewLocations } from 'actions/nav';

import { tierUpdated } from 'features/biller/supportActions';
import { selectBasicMleLocations } from 'features/enforcement/selectors';
import { removeLocations } from 'features/enforcement/slice';
import {
  BILLER_NEW_LOCATIONS_TIER_VIEW_MODAL,
  SECOND_LOCATION_TRIAL_SETTINGS_MODAL,
} from 'features/modals/constants';

import { trackMultilocationTrialEvent, trackUxEvent } from 'util/tracking';
import {
  EVENT_ACTIONS,
  EVENT_CATEGORIES,
  PRODUCT_AREAS,
  TRACK_ACTION_TYPES,
  TRACK_ACTIONS,
  TRACK_EVENTS,
  TRACK_SOURCES,
} from 'util/tracking_constants';
import { updateGlobalUxTrackingData } from 'util/trackingModules/helpers';

import { showMultilocationEnforcementDialog } from '../../actions/modals';
import { PAST_DUE_DIALOG_VIEW_KEY } from '../monetization/PastDueSubscriptionDialog/constants';

import ACTIONS from './implementations/actions';
import { MANAGE_PLANS_PATH, MODULE_NAME } from './constants';

export const changePaymentMethod = createAsyncThunk(
  `${MODULE_NAME}/changePaymentMethod`,
  (payload, { dispatch }) =>
    dispatch(ACTIONS[payload.biller].changePaymentMethod(payload))
);

export const addHiringBoost = createAsyncThunk(
  `${MODULE_NAME}/addHiringBoost`,
  (payload, { dispatch }) =>
    dispatch(ACTIONS[payload.biller].addHiringBoost(payload))
);

export const changeTier = createAsyncThunk(
  `${MODULE_NAME}/changeTier`,
  (payload, { dispatch }) =>
    dispatch(ACTIONS[payload.biller].changeTier(payload))
);

export const adminChangeTier = createAsyncThunk(
  `${MODULE_NAME}/adminChangeTier`,
  (payload, { dispatch }) =>
    dispatch(ACTIONS[payload.biller].adminChangeTier(payload))
);

export const putSubscription = createAsyncThunk(
  `${MODULE_NAME}/putSubscription`,
  (
    {
      tier,
      locations,
      billingCycle,
      newLocations,
      paymentMethodId,
      newPaymentMethod,
      discountId,
      uxContext = {},
    },
    { rejectWithValue, dispatch }
  ) =>
    putJSON('/biller/subscriptions.json', {
      tier_id: tier.get('id'),
      location_ids: locations.map(l => l.get('id')).toJS(),
      annual: billingCycle === 'annual',
      new_locations: newLocations,
      payment_method_id: paymentMethodId,
      new_payment_method: newPaymentMethod,
      discount_id: discountId,
    })
      .then(response => {
        if (newLocations && newLocations.size) {
          // location_count needs to be updated before upgrade successful event fires
          updateGlobalUxTrackingData({
            location_count: response.data.locations.length,
          });
          trackUxEvent({
            eventAction: EVENT_ACTIONS.NEW_LOCATION_ADDED,
            productArea: PRODUCT_AREAS.BILLING_PAGE,
            eventCategory: EVENT_CATEGORIES.BILLING_PAGE,
            actionType: TRACK_ACTION_TYPES.VIEW,
          });
        }

        trackUxEvent({
          productArea: PRODUCT_AREAS.BILLING_PAGE,
          eventCategory: EVENT_CATEGORIES.BILLING_PAGE,
          eventAction: EVENT_ACTIONS.UPGRADE_SUCCESSFUL,
          actionType: TRACK_ACTION_TYPES.VIEW,
          properties: uxContext,
        });

        dispatch(addNewLocations(response.data.locations));
      })
      .catch(err => err.response.json().then(body => rejectWithValue(body)))
);

export const adminPutSubscription = createAsyncThunk(
  `${MODULE_NAME}/adminPutSubscription`,
  (
    {
      tier,
      companyId,
      locations,
      billingCycle,
      paymentMethodId,
      newPaymentMethod,
      discountId,
      nonce,
      trialPeriodDate,
      immediateDowngrade,
    },
    { rejectWithValue }
  ) =>
    putJSON(`/admin/companies/${companyId}/biller_team_app_plan_change.json`, {
      tier_id: tier.get('id'),
      location_ids: locations.map(l => l.get('id')).toJS(),
      annual: billingCycle === 'annual',
      payment_method_id: paymentMethodId,
      new_payment_method: newPaymentMethod,
      discount_id: discountId,
      trial_period_date: trialPeriodDate,
      immediate_downgrade: immediateDowngrade,
      nonce,
    }).catch(err => err.response.json().then(body => rejectWithValue(body)))
);

export const requestPaymentMethod = createAsyncThunk(
  `${MODULE_NAME}/requestPaymentMethod`,
  ({ biller }, { dispatch }) =>
    dispatch(ACTIONS[biller].requestPaymentMethod()).then(unwrapResult)
);

export const updateTier = createAsyncThunk(
  `${MODULE_NAME}/updateTier`,
  (
    {
      tier,
      locations,
      billingCycle,
      biller,
      newLocations,
      discountId = null,
      uxContext = {},
      isBillingModalSourceMle,
      isBillingModalSourcePastDue,
      showPastDueDialog,
      payrollLocationAdded = false,
    },
    { dispatch, getState, rejectWithValue }
  ) => {
    const uxContextCopy = { ...uxContext };

    return dispatch(requestPaymentMethod({ biller }))
      .then(unwrapResult)
      .then(({ paymentMethodId = null, newPaymentMethod = false }) =>
        dispatch(
          putSubscription({
            tier,
            locations,
            billingCycle,
            newLocations,
            paymentMethodId,
            newPaymentMethod,
            discountId,
            uxContext: uxContextCopy,
          })
        )
      )
      .then(unwrapResult)
      .then(() => {
        if (payrollLocationAdded) {
          return dispatch(showNewPayrollLocationSuccessModal());
        }
        if (newLocations?.size > 0) {
          return dispatch(showNewLocationsSuccessModal(newLocations));
        }

        if (isBillingModalSourcePastDue) {
          dispatch(hideModal());
          return dispatch(
            showPastDueDialog({
              initialViewKey: PAST_DUE_DIALOG_VIEW_KEY.restored_to_paid,
            })
          );
        }

        if (isBillingModalSourceMle) {
          const basicMleLocations = selectBasicMleLocations(getState());

          const locationIdsForUpgrade = locations
            .toJS()
            .map(location => location.id);
          const locationsToArchive = basicMleLocations.filter(
            location => !locationIdsForUpgrade.includes(location.id)
          );

          if (locationsToArchive.length > 0) {
            return dispatch(
              removeLocations({ locations: locationsToArchive })
            ).then(() => {
              dispatch(showMultilocationEnforcementDialog({ currentStep: 4 }));
            });
          }
          return dispatch(
            showMultilocationEnforcementDialog({ currentStep: 4 })
          );
        }

        return dispatch(tierUpdated());
      })
      .catch(err => rejectWithValue(err));
  }
);

export const adminUpdateTier = createAsyncThunk(
  `${MODULE_NAME}/adminUpdateTier`,
  (
    {
      companyId,
      tier,
      locations,
      billingCycle,
      biller,
      trialPeriodDate,
      discountId = null,
      immediateDowngrade = false,
    },
    { dispatch, rejectWithValue }
  ) =>
    dispatch(requestPaymentMethod({ biller }))
      .then(unwrapResult)
      .then(
        ({ paymentMethodId = null, newPaymentMethod = false, nonce = null }) =>
          dispatch(
            adminPutSubscription({
              companyId,
              tier,
              locations,
              billingCycle,
              paymentMethodId,
              newPaymentMethod,
              discountId,
              nonce,
              trialPeriodDate,
              immediateDowngrade,
            })
          )
      )
      .then(unwrapResult)
      .then(() => dispatch(hideModal()))
      .catch(err => rejectWithValue(err))
);

export const downgradeTier = createAsyncThunk(
  `${MODULE_NAME}/downgradeTier`,
  (payload, { dispatch }) => {
    dispatch(ACTIONS[payload.biller].downgradeTier(payload));
  }
);

export const submitDecoupledDowngrade = createAsyncThunk(
  `${MODULE_NAME}/submitDecoupledDowngrade`,
  (
    {
      tier,
      billingCycle,
      paymentMethodId = null,
      newPaymentMethod,
      discountId = null,
      reason,
      customReason,
      reasonElaboration,
      suggestions,
      uxContext,
    },
    { rejectWithValue }
  ) =>
    putJSON('/biller/subscriptions/downgrade.json', {
      target_tier_id: tier.get('id'),
      annual: billingCycle === 'annual',
      payment_method_id: paymentMethodId,
      new_payment_method: newPaymentMethod,
      discount_id: discountId,
      downgrade_reason: !!reason ? reason : customReason,
      reason_elaboration: reasonElaboration,
      suggestions,
    })
      .then(() => {
        trackUxEvent({
          productArea: PRODUCT_AREAS.BILLING_PAGE,
          eventCategory: EVENT_CATEGORIES.BILLING_PAGE,
          eventAction: EVENT_ACTIONS.DOWNGRADE_SCHEDULED,
          actionType: TRACK_ACTION_TYPES.VIEW,
          properties: uxContext,
        });
      })
      .catch(err => rejectWithValue(err))
);

export const downgradeLocation = createAsyncThunk(
  `${MODULE_NAME}/downgradeLocation`,
  (
    {
      tier,
      billingCycle,
      biller,
      discountId = null,
      reason,
      customReason,
      reasonElaboration,
      suggestions,
      uxContext = {},
    },
    { dispatch, rejectWithValue }
  ) => {
    const uxContextCopy = { ...uxContext };

    return dispatch(requestPaymentMethod({ biller }))
      .then(unwrapResult)
      .then(({ paymentMethodId = null, newPaymentMethod = false }) =>
        dispatch(
          submitDecoupledDowngrade({
            tier,
            billingCycle,
            paymentMethodId,
            newPaymentMethod,
            discountId,
            reason,
            customReason,
            reasonElaboration,
            suggestions,
            uxContext: uxContextCopy,
          })
        )
      )
      .then(unwrapResult)
      .then(() => dispatch(tierUpdated()))
      .catch(err => rejectWithValue(err));
  }
);

export const submitDowngradeForm = createAsyncThunk(
  `${MODULE_NAME}/submitDowngradeForm`,
  (
    {
      tier,
      reason,
      customReason,
      reasonElaboration,
      suggestions,
      uxContext = {},
    },
    { dispatch, rejectWithValue }
  ) => {
    const uxContextCopy = { ...uxContext };

    return dispatch(
      submitDecoupledDowngrade({
        tier,
        billingCycle: 'monthly',
        reason,
        customReason,
        reasonElaboration,
        suggestions,
        uxContext: uxContextCopy,
      })
    )
      .then(() => {
        window.location = MANAGE_PLANS_PATH;
      })
      .catch(err => rejectWithValue(err));
  }
);

export const addLocations = createAsyncThunk(
  `${MODULE_NAME}/addLocations`,
  (
    {
      inTrial,
      canAddSecondLocationOnTrial,
      newLocations,
      biller,
      payrollLocationAdded = false,
      uxContext = {},
    },
    { rejectWithValue, dispatch }
  ) => {
    if (inTrial || canAddSecondLocationOnTrial) {
      return postJSON('/locations/create_second_location_for_trial', {
        location_to_be_created: newLocations.get(0).toJS(),
      })
        .then(response => {
          const { productArea, eventCategory } = uxContext;

          updateGlobalUxTrackingData({
            location_count: 2,
          });
          trackUxEvent({
            productArea,
            eventCategory,
            eventAction: EVENT_ACTIONS.NEW_LOCATION_ADDED,
            actionType: TRACK_ACTION_TYPES.VIEW,
          });

          dispatch(
            showModal(SECOND_LOCATION_TRIAL_SETTINGS_MODAL, {
              deprecatedModal: true,
              biller,
              locationName: response.location_name,
              locationId: response.location_id,
              overflowVisible: true,
              noPadding: true,
              auto: true,
              onClose: closeModal => {
                closeModal();
                return trackMultilocationTrialEvent(
                  TRACK_EVENTS.ADD_LOCATION,
                  TRACK_ACTIONS.DISMISS,
                  {
                    source: TRACK_SOURCES.LOCATION_SUCCESS,
                  }
                );
              },
            })
          );
        })
        .catch(err => err.response.json().then(body => rejectWithValue(body)));
    }
    return dispatch(
      showModal(BILLER_NEW_LOCATIONS_TIER_VIEW_MODAL, {
        deprecatedModal: true,
        auto: true,
        noPadding: true,
        newLocations,
        payrollLocationAdded,
        biller,
      })
    );
  }
);

export const newLocations = createAsyncThunk(
  `${MODULE_NAME}/newLocations`,
  (payload, { dispatch }) =>
    dispatch(ACTIONS[payload.biller].newLocations(payload))
);

export const applyDiscount = createAsyncThunk(
  `${MODULE_NAME}/applyDiscount`,
  ({ discountCode, planName, billingCycle = null }, { rejectWithValue }) =>
    fetchJSON(
      `/biller/verify_code.json?${queryString.stringify({
        partner_code_id: discountCode,
        plan_name: planName,
        billing_cycle: billingCycle,
      })}`
    ).catch(err => err.response.json().then(body => rejectWithValue(body)))
);

export const applyAdminDiscount = createAsyncThunk(
  `${MODULE_NAME}/applyDiscount`,
  (
    { discountCode, planName, billingCycle, companyId },
    { rejectWithValue }
  ) => {
    const endpoint = `/admin/companies/${companyId}/biller_verify_discount_code`;

    return fetchJSON(
      `${endpoint}.json?${queryString.stringify({
        partner_code_id: discountCode,
        plan_name: planName,
        billing_cycle: billingCycle,
      })}`
    ).catch(err => err.response.json().then(body => rejectWithValue(body)));
  }
);

export const submitInitialSalesTaxTotals = createAsyncThunk(
  `${MODULE_NAME}/submitInitialSalesTaxTotals`,
  ({ location, taxableAmount }, { rejectWithValue }) =>
    postJSON(`/biller/anrok/sales_taxes.json`, {
      location,
      taxable_amount: taxableAmount,
    }).catch(err => err.response.json().then(body => rejectWithValue(body)))
);

export const adminSubmitInitialSalesTaxTotals = createAsyncThunk(
  `${MODULE_NAME}/adminSubmitInitialSalesTaxTotals`,
  ({ companyId, location, taxableAmount }, { rejectWithValue }) =>
    postJSON(`/admin/companies/${companyId}/biller_sales_taxes.json`, {
      taxable_location: location,
      taxable_amount: taxableAmount,
    }).catch(err => err.response.json().then(body => rejectWithValue(body)))
);
