import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { fetchJSON } from 'api/fetch';
import { RootState } from 'initializers/types';

import { MY_WEEK_SLICE } from './constants';

export interface Shift {
  id: number;
  color: string;
  unscheduled: boolean;
  owner_id: number;
  owner_type: 'Job' | 'Location';
  start_at: string;
  end_at: string;
  role_name: string | null;
}

interface Job {
  id: number;
  location_id: number;
  location_name: string;
}

export interface ShiftWithLocation extends Shift {
  location_name: string;
}

interface MyWeekState {
  userShifts: ShiftWithLocation[];
  openShifts: ShiftWithLocation[];
  selectedTab: 'my-schedule' | 'open-shifts';
  loading: boolean;
  error: string | null;
  jobs: Job[];
}

const initialState: MyWeekState = {
  userShifts: [],
  openShifts: [],
  selectedTab: 'my-schedule',
  loading: true,
  error: null,
  jobs: [],
};

export const fetchProfile = createAsyncThunk(
  `${MY_WEEK_SLICE}/fetchProfile`,
  async (params: { start_date: string; end_date: string }) => {
    const profileParams = new URLSearchParams({
      ...params,
      format: 'json',
    });

    return fetchJSON(`/profile?${profileParams}`);
  }
);

export const fetchShifts = createAsyncThunk(
  `${MY_WEEK_SLICE}/fetchShifts`,
  async (params: { start_date: string; end_date: string }) => {
    const shiftsParams = new URLSearchParams({
      ...params,
      only_future: 'true',
      format: 'json',
    });

    return fetchJSON(`/user/shifts?${shiftsParams}`);
  }
);

const addLocationToShifts = (
  shifts: Shift[],
  jobs: Job[]
): ShiftWithLocation[] =>
  shifts.map(shift => {
    const matchingJob =
      shift.owner_type === 'Job'
        ? jobs.find(job => job.id === shift.owner_id)
        : jobs.find(job => job.location_id === shift.owner_id);

    return {
      ...shift,
      location_name: matchingJob?.location_name || '',
    };
  });

const myWeekSlice = createSlice({
  name: MY_WEEK_SLICE,
  initialState,
  reducers: {
    setSelectedTab: (state, action) => {
      state.selectedTab = action.payload;
    },
  },
  extraReducers: builder => {
    builder
      .addCase(fetchShifts.pending, state => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchShifts.fulfilled, (state, action) => {
        // Store raw shifts first (locations not given by shifts endpoint)
        const { user_shifts, open_shifts } = action.payload;

        // If we already have jobs, add locations
        if (state.jobs.length) {
          state.userShifts = addLocationToShifts(user_shifts, state.jobs);
          state.openShifts = addLocationToShifts(open_shifts, state.jobs);
          state.loading = false;
        } else {
          // Store shifts but keep loading true until we get locations
          state.userShifts = user_shifts.map((s: Shift) => ({
            ...s,
            location_name: '',
          }));
          state.openShifts = open_shifts.map((s: Shift) => ({
            ...s,
            location_name: '',
          }));
        }
      })
      .addCase(fetchProfile.pending, state => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchProfile.fulfilled, (state, action) => {
        state.jobs = action.payload.jobs;

        if (state.userShifts.length || state.openShifts.length) {
          state.userShifts = addLocationToShifts(state.userShifts, state.jobs);
          state.openShifts = addLocationToShifts(state.openShifts, state.jobs);
        }
        state.loading = false; // Only set loading to false after we have locations
      });
  },
});

// Selectors
export const selectUserShifts = (state: RootState) =>
  state.get(MY_WEEK_SLICE).userShifts;

export const selectOpenShifts = (state: RootState) =>
  state.get(MY_WEEK_SLICE).openShifts;

export const selectSelectedTab = (state: RootState) =>
  state.get(MY_WEEK_SLICE).selectedTab;

export const selectShiftsLoading = (state: RootState) =>
  state.get(MY_WEEK_SLICE).loading;

export const selectShiftsError = (state: RootState) =>
  state.get(MY_WEEK_SLICE).error;

export const { setSelectedTab } = myWeekSlice.actions;

export const { reducer } = myWeekSlice;
